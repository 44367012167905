export const audioImg = require('../assets/audio.svg');
export const uploadImg = require('../assets/upload.svg');
export const searchMobileImg = require('../assets/search-mobile.svg');
export const searchImg = require('../assets/lense.svg');
export const uploadIcon = require("../assets/uploadIcon.svg");
export const checkboxUnselect = require("../assets/checkboxUnselect.svg");
export const checkboxSelected = require("../assets/checkboxSelected.svg");
export const searchIconSmall = require("../assets/searchIconSmall.svg");
export const drawerClose = require("../assets/drawerClose.svg");
export const menuIcon = require("../assets/menuIcon.svg");
export const buildingInfoLogo = require("../assets/logo_buildinfo.png");
export const personImage = require("../assets/person_image.png");
export const searchIconBig = require("../assets/searchIconBig.svg");
export const micIcon = require("../assets/micIcon.svg");
export const arrowIcon = require("../assets/arrow.svg");
export const confirmationIcon = require("../assets/confirmation.svg");
export const errorMessageIcon = require("../assets/error_popup.svg");
export const audioStartedImg = require('../assets/audioStarted.svg');
export const subSecDeleteButton = require("../assets/subSecDeleteButton.svg");
export const alertLogo = require("../assets/alertLogo.svg");
export const plusIcon = require("../assets/plusIcon.svg");
export const circlePlusIcon = require("../assets/addCircle.svg");
export const circleOutline = require("../assets/circleoutline.svg");
export const highlightOff = require("../assets/highlight_off.svg");
export const deleteIcon = require("../assets/delete.svg");
export const galleryImage = require("../assets/galleryImage.svg");
export const cameraAccess = require("../assets/cameraAccess.svg");
export const deny = require("../assets/deny.svg");
export const cameraImage = require("../assets/cameraImage.svg");
export const back = require("../assets/back.svg");
export const popupCrossIcon = require("../assets/popupCrossIcon.svg");
export const editIcon = require("../assets/edit.svg");