import { Box, Button, Typography, styled,TextField } from "@material-ui/core";
import React from "react";
import Header from "../../user-profile-advanced/src/Header.web";
import { CustomFormSidebar } from "./CustomFormSidebar";
import { GlobalSearchBar } from "./GlobalSearchBar";
import {
  CustomFormWrapperController,
  Props,
} from "./CustomFormWrapperController";
import ConfirmationDialog from "./ConfiramtionPopUp";
import SubsectionDeletePopup from "./SubsectionDeletePopup.web";
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import SearchIcon from '@material-ui/icons/Search';
import {editIcon,popupCrossIcon} from "./assets"

const CustomPopup = styled(Box)({
  position: "absolute",
  top: "50%",
  left: "50%",
  zIndex: 2,
  borderRadius: "8px",
  backgroundColor: "white",
  transform: "translate(-50%, -50%)",
  flexDirection: "column",
  boxShadow: "0 0 10px rgba(0, 0, 0, 0.5)",
  padding: "0px 0px 20px 0px",
  display: "flex",
  width: "50%",
  "@media (max-width: 500px)": {
    position: "fixed",
    zIndex: 1102,
    width: "calc(100% - 48px) !important"
  },
  "& .MuiDialog-paperFullWidth": {
    backgroundColor: "#FFF",
    padding: "0px 47px 0px 21px",
    borderRadius: "8px",
    width: "100%",
    boxSizing: "border-box",
    "@media (max-width: 600px)": {
      height: "unset",
      maxHeight: "unset",
      padding: "20px"
    },
    "@media (max-width: 450px)": {
      padding: "10px"
    }
  },
  "@media (max-width: 992px)": {
    width: "70%"
  },
  "@media (max-width: 435px)": {
    width: "80%"
  },
});

const CloseDivIcon = styled(Box)({
  justifyContent: "flex-end",
  display: "flex",
  margin: "20px 20px 0px 0px",
  "@media(max-width:500px)":{
    margin: "0"
  }
});

const CloseIcon = styled("img")({
  cursor: "pointer",
  position: "absolute",
  top: "35px",
  "@media(max-width:500px)":{
    top: "28px",
    right: "24px"
  }
});


const MiddleContainer = styled(Box)({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
});


const MainContiner = styled(Box)({
  boxSizing: "border-box"
});

const BorderTop = styled(Box)({
  marginTop:"40px",
 border: "1px solid #E2E8F0",
 "@media (max-width: 992px)": {
  display: "none"
 }
});

const Buttondiv = styled(Box)({
  padding: "30px 20px 0px 0px",
  textAlign: "end",
  "@media (max-width: 1250px)": {
    marginTop: "30px"
  },
  "@media (max-width: 992px)": {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    gap: "20px",
    textAlign: "center",
    padding: "10px 20px 0px 20px"
  },
  "@media(max-width:500px)":{
    padding: "10px 30px 0px 30px"
  }
});

const CancelButton = styled(Button)({
  width: "120px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  marginRight: "10px",
  background: "var(--scio-services-light-blue-50-light-blue, #C2E1FA)",
  textTransform: "none",
  color: "#0090CF",
  "&:hover":{
    background: "var(--scio-services-light-blue-50-light-blue, #C2E1FA)",
    color: "#0090CF"
  },
  "@media (max-width: 992px)": {
    width:"100%",
    marginRight: "0px"
  }
});

const ConfirmButton = styled(Button)({
  width: "120px",
  height: "44px",
  padding: "10px 16px",
  justifyContent: "center",
  alignItems: "center",
  gap: "8px",
  flexShrink: 0,
  borderRadius: "8px",
  background: "var(--Scio-Services-Gradient, linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%))",
  textTransform: "none",
  color: "#FFFFFF",
  "@media (max-width: 992px)": {
    width:"100%"
  }
});

const Inputdiv = styled(Box)({
  marginTop: "30px",
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-start",
  padding: "0px 30px 0px 30px",
  gap: "8px",
  "@media (max-width: 900px)": {
    marginTop: "20px"
  },
  "@media (max-width: 500px)": {
    marginTop: "24px",
    gap: "4px"
  }
});

const Heading = styled(Typography)({
  color: "var(--scio-services-basic-black, #181818)",
  fontFamily: "Poppins",
  fontSize: "24px",
  fontStyle: "normal",
  fontWeight: 700,
  lineHeight: "normal",
  padding: "5px 30px 10px 30px",
  "@media (max-width: 500px)": {
    padding: "0 0 0 30px",
    margin: "22px 0"
  },
  "@media (max-width: 375px)": {
    fontSize: "20px"
  }
});

const HrBorder = styled("hr")({
  border: "1px solid #E2E8F0",
  "@media(max-width: 500px)":{
    margin: "0"
  }
});

const LabelText = styled(Typography)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 600,
  lineHeight: "normal",
  marginRight: "0px",
  alignItems: "flex-start",
  marginBottom: "0px",
});

const Textfield = styled(TextField)({
  color: "#424C53",
  fontFamily: "Poppins",
  fontSize: "16px",
  fontStyle: "normal",
  fontWeight: 500,
  "& .MuiOutlinedInput-notchedOutline": {
    borderRadius: "8px"
  },
  "& .MuiOutlinedInput-input": {
    padding: "10px 8px",
    height: "30px"
  },
  background: "var(--scio-services-neutral-50-neutral, #F9FAFA)",
  "@media(max-width: 550px)":{
    "& .MuiOutlinedInput-input": {
      height: "17px"
    }
  },
  "@media(max-width: 500px)":{
    "& .MuiOutlinedInput-input": {
      height: "56px",
      boxSizing: "border-box"
    }
  }
});


export const ListItem = styled("li")({
  display: "flex",
  alignItems: "center",
  marginTop: "8px",
  gap: "8px",
  color: " var(--scio-services-neutral-300-neutral, #A6B1B7)",
  fontFamily: "Inter",
  fontSize: "14px",
  fontStyle: "normal",
  fontWeight: 300,
  lineHeight: "normal"
});

export const PopupBlurBackground = styled(Box)({
  background: "rgba(24, 24, 24, 0.40)",
  "-webkit-backdrop-filter": "blur(5px)",
  position: "absolute",
  borderRadius: "16px",
  border: "1px solid transparent",
  zIndex: 1,
  height: "100%",
  left: -1,
  width: "100%",
  top: -1,
  right:-1,
  "@media (max-width: 500px)": {
    position: "fixed",
    zIndex: 1101
  },
});

const ErrorPopupBox = styled(Box)({
  display: "flex",
  gap: "68px",
  justifyContent: "center",
  alignItems: "center",
  width: "100%",
  marginTop: "40px",
  "@media (max-width: 500px)": {
    width: "calc(100% - 60px)",
    margin: "auto",
    marginTop: "24px"
  },
});

const ErrorLoginAlertPopup = styled(Box)({
  minWidth: "87%",
  borderTop: "4px solid #DC2626",
  padding: "12px 10px",
  borderRadius: "4px",
  backgroundColor: "#FEE2E2",
  "@media (max-width: 1024px)": {
    minWidth: "80%"
  },
  "@media (max-width: 550px)": {
    minWidth: "0%",
    width: "100%"
  },
  "@media (max-width: 375px)": {
    minWidth: "50%",
    padding: "12px 30px 12px 16px",
  }
});


const ErrorLoginInput = styled(Typography)({
  color: "#DC2626",
  fontFamily: "Poppins",
  fontSize: "12px !important",
  fontStyle: "normal",
  fontWeight: 400,
  lineHeight: "18px",
  textAlign: "center",
  "@media(max-width:500px)":{
    textAlign: "left",
    "& span":{
      fontWeight: 700
    }
  }
});

const ContentWrapper = styled(Box)({
  margin: "100px 40px 0px 0px",
  width:'100%',
  padding: 0,
  height: 'calc(100vh - 120px)',
  overflow: 'hidden',
  overflowY: 'scroll',

  "@media(max-width: 900px)": {
    margin: "115px 0px 0px 0px",
    padding: "13px 0px 25px 0px",
  },
  "@media(max-width: 435px)": {
    margin: "46px 0px 0px 0px",
  }
});

const SurveyTitleBox = styled(Box)({
  textAlign: 'center',
  marginBottom:"15px",
 
  "@media(max-width: 900px)": {
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    margin: '13px 24px 20px 24px',
  }
})

const SurveyTitle = styled(Typography)({
  fontFamily: "Poppins, Inter, sans-serif",
  fontWeight: 400,
  fontSize: "30px",
  lineHeight: "45px",
  textAlign: "center",
  padding: "0 10px",
  "@media(max-width: 435px)": {
    fontSize: "18px",
    fontWeight: 700,
    textTransform: "uppercase",
    marginBottom: 0,
    lineHeight: "24px",
  }
});

const ButtonsWrapper = styled("div")({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "flexStart",
  flexDirection: "row",
  marginTop: "40px",
  "@media(max-width: 900px)": {
    padding: "0 24px"
  },
  "@media(max-width: 650px)": {
    marginTop: "0px",
    flexDirection: "column",
    justifyContent: "center",
    gap: "20px",
    alignItems: "center",
  },
});

export const SaveButton = styled(Button)({
  width: "183px",
  padding: "10px 16px",
  textAlign: "center",
  borderRadius: "8px",
  backgroundColor: "#EBF5FE",
  textTransform: "none",
  color: "#0090CF",
  fontWeight: 500,
  fontFamily: "Poppins, Inter",
  "&:hover":{
  backgroundColor: "#EBF5FE",
  },
  "@media(max-width: 650px)": {
    width: "70%",
  },
});

export const NextButton = styled(SaveButton)({
  background: "linear-gradient(to right, #34B9FC, #7B61FD)",
  color: "white",
});

export const AddSimilarSectionButton = styled(Button)({
  padding: "10px 80px 10px 80px",
  marginTop: "20px",
  textTransform: "none",
  textAlign: "center",
  backgroundColor: "#EBF5FE",
  color: "#0090CF",
  borderRadius: "8px",
  fontFamily: "Poppins, Inter",
  fontWeight: 500,
  "&:hover":{
    backgroundColor: "#EBF5FE",
  },
  "@media(max-width: 650px)": {
    width: "70%",
  },
});


export const Back = styled(ArrowBackIcon)({
  display: 'none',
  "@media(max-width: 900px)":{
    height: '45px',
    cursor:"pointer",
    display: "block",
    zIndex: 1,
    transform: "Scale(1.5)"
  },
  "@media(max-width: 435px)":{
    height: '24px',
    transform: "Scale(1)"
  }
})
export const Search = styled(SearchIcon)({
  display: 'none',
  "@media(max-width: 900px)":{
    height: '45px',
    display: "block",
    cursor:"pointer",
    zIndex: 1,
    transform: "Scale(1.5)"
  },
  "@media(max-width: 435px)":{
    height: '24px',
    transform: "Scale(1)"

  }
})
export class CustomFormWrapper extends CustomFormWrapperController {
  constructor(props: Props) {
    super(props);
  }

  render() {
    const {
      onLinkClick,
      surveyName,
      links,
      children,
      onNextBtnClick,
      onBackBtnClick,
      hasNextSection = true,
      onSaveBtnClick,
      searchSpecificSec,
      handleSectionSearchBar,
      specificSecSearchesData,
      sectionSubSectionList,
      navigationSearchSections,
      listOfSectionsSubsections,
      linksSectionsSubsections,
      name,
      openAccordions,
      deleteSubSecPopup,
      handleCloseDeleteSubsecPopup,
      subSecName,
      handleDeleteElementAccordion,
    } = this.props;
    const { hasOpenSidebar, hasOpenGloableSearchBar } = this.state;

    return (
      <>
      <Box>
        <div style={{zIndex:10}}>
        <Header
        data-test-id="header-id"
          username={localStorage.getItem("user-name")}
          propfileId={localStorage.getItem("user-id")}
          profileImg=""
          onMenuClick={() => this.setOpenSidebar(!hasOpenSidebar)}
          onHeaderClick={()=>this.props.navigation.navigate("LandingPage")}
          onProfileClick={()=>this.props.navigation.navigate("UserProfileAdvance")}
        />
        </div>        
        <div style={{display:'flex', marginTop:'0px', gap:'20px', height: '100%', position: 'relative'}}>
        <CustomFormSidebar
          onLinkClick={onLinkClick}
          isOpen={hasOpenSidebar}
          handleClose={() => this.setOpenSidebar(false)}
          links={links}
          searchSpecificSec={searchSpecificSec}
          specificSecSearchesData={specificSecSearchesData}
          handleSectionSearchBar={handleSectionSearchBar}
          setOpenSidebar={this.setOpenSidebar}
          sectionSubSectionList={sectionSubSectionList}
          navigationSearchSections={navigationSearchSections}
          listOfSectionsSubsections={listOfSectionsSubsections}
          linksSectionsSubsections={linksSectionsSubsections}
          subSectionName={name}
          openAccordions={openAccordions}
          
        />
        <ConfirmationDialog
          open={this.props.showPopUp}
          handleClose={this.props.onPopUpClose}
          saveBtnResponse={this.props.saveBtnResponse}
          error={this.props.error}
        />
        <ContentWrapper
          style={{
            ...(hasOpenSidebar && !this.isDesktop && { display: "none" }),
          }}
        >
            <SurveyTitleBox>
              <Back data-test-id="back-btn-id" onClick={onBackBtnClick} />
              <Box style={{display:"flex",justifyContent:"center",gap:"90px"}}>
                <SurveyTitle data-test-id="section-title">
                        Survey Form: {surveyName}                
                </SurveyTitle>
                {/* <button data-test-id="popupId" onClick={this.customPopUp} style={{fontSize:"13px",border:"none",background:"none",outline:"none",cursor:"pointer"}}>
                    <img style={{width:"15px", height:"13px"}} src={editIcon}/> Edit Survey Name and Property owner
                  </button> */}
              </Box>
             
              <Search data-test-id="global-search-btn"  onClick={this.toggleSearchbar} />
            </SurveyTitleBox>

              {hasOpenGloableSearchBar &&  <GlobalSearchBar
                handleSearch={this.props.handleGlobalSearch}
                handleError={this.props.handleError}
                navigation={this.props.navigation}
              />}      
          {children}
          <ButtonsWrapper>
            <SaveButton
              data-test-id="save-btn"
              onClick={() => onSaveBtnClick("Started")}
            >
              Save
            </SaveButton>
            {hasNextSection ? (
              <NextButton data-test-id="next-btn" onClick={onNextBtnClick}>
                Next
              </NextButton>
            ) : (
              <NextButton
                data-test-id="submit-btn"
                onClick={() => this.props.onSubmit("Completed")}
              >
                Submit
              </NextButton>
            )}
          </ButtonsWrapper>
        {deleteSubSecPopup && <SubsectionDeletePopup 
        handleCloseDeleteSubsecPopup={handleCloseDeleteSubsecPopup}
         message={`Are you sure you want to delete ${subSecName}?`} 
         firstBtnText={"No"} 
         secondBtnText={"Yes"}
         handleDeleteElementAccordion={handleDeleteElementAccordion}
         />}
        </ContentWrapper>
        </div>
      </Box>

      {this.state.openPopup && (
        <CustomPopup>
          <CloseDivIcon>
            <CloseIcon src={popupCrossIcon} onClick={this.handleClosePopup} />
          </CloseDivIcon>
          <MiddleContainer>
            <MainContiner>
              <form data-test-id="continueId" onSubmit={this.createSurveyName}>
                <Heading>Survey Template</Heading>
                <HrBorder></HrBorder>
                {this.state.validationsError !== '' && (
                  <ErrorPopupBox>
                    <ErrorLoginAlertPopup>
                      <ErrorLoginInput dangerouslySetInnerHTML={{__html: this.state.validationsError}} />
                    </ErrorLoginAlertPopup>
                  </ErrorPopupBox>
                )}
                <Inputdiv>
                  <LabelText>Survey Name *</LabelText>
                  <Textfield
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={this.state.surveyName}
                    placeholder="Enter Name"
                    onChange={(e) => this.setSurveyName(e.target.value)}
                    error={this.state.surveyNameError}
                    data-test-id="surveyNameId"
                  />
                </Inputdiv>
                <Inputdiv>
                  <LabelText>Property Owner *</LabelText>
                  <Textfield
                    fullWidth
                    variant="outlined"
                    type="text"
                    value={this.state.propertyOwner}
                    placeholder="Enter Name"
                    onChange={(e) => this.setPropertyOwner(e.target.value)}
                    error={this.state.propertyOwnerError}
                    data-test-id="propertyOwnerId"
                  />
                </Inputdiv>
                <BorderTop></BorderTop>
                <Buttondiv>
                  <CancelButton data-test-id="cancelId" onClick={this.handleClosePopup}>Cancel</CancelButton>
                  <ConfirmButton data-test-id="continueBtn" type="submit">Continue</ConfirmButton>
                </Buttondiv>
              </form>
            </MainContiner>
          </MiddleContainer>
        </CustomPopup>
      )}

    </>

    );
    
  }
}
