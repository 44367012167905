import React from "react";
// Customizable Area Start
import { Box, Modal, Typography, styled } from "@material-ui/core";
import { buildingInfoLogo, galleryImage, cameraAccess, deny, cameraImage, back } from "./assets";
import UploadNewPhotoMobileController from "./UploadNewPhotoMobileController";
import Header from "../../user-profile-advanced/src/Header.web";
import Sidebar from "../../navigationmenu/src/SidebarNavigationMenu.web";
export const configJSON = require("./config");
// Customizable Area End

// Customizable Area Start
const UploadMediaContainer = styled(Box)({
    borderRadius: "8px 8px 0px 0px",
    height: "100%",
    flexDirection: "column",
    display: "flex",
    justifyContent: "space-between",
    boxShadow: "0px -1px 8px 0px rgba(0, 0, 0, 0.08)"
});

const HeaderIconMainBox = styled(Box)({
    display: "flex",
    alignItems: "center"
});

const BackIcon = styled("img")({
    height: "20px",
    width: "20px",
    padding: "15px 20px 0px 24px"
});

const UploadMediaTitleText = styled(Typography)({
    color: "#191C1E",
    fontFamily: "Poppins",
    fontSize: "18px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    padding: "15px 0px 0px 0px"
});

const DashedContainer = styled(Box)({
    height: "441px",
    margin: "10px 20px 0px 20px",
    backgroundImage: "linear-gradient(to right, black 33%, rgba(255,255,255,0) 0%)",
    backgroundPosition: "bottom",
    backgroundSize: "3px 1px",
    backgroundRepeat: "repeat-x",
});

const GalleryBox = styled(Box)({
    height: "50%",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    border: "1px dashed #C0C8CD",
});

const GalleryImage = styled("img")({
    height: "90px",
    width: "90px",
    marginBottom: "10px"
});

const ImageTitleText = styled(Typography)({
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "24px",
    background: "var(--Scio-Services-Gradient, linear-gradient(90deg, #34B9FC, #7B61FD 100%))",
    backgroundClip: "text"
});

const ModalTextMainBox = styled(Box)({
    display: "flex",
    height: "100px",
    flexDirection: "column",
    margin: "0px 24px 0px 24px"
});

const AllowAccessText = styled(Typography)({
    color: " var(--Scio-Services-Basic-Black, #181818)",
    fontFamily: "Poppins",
    fontSize: "25px",
    fontStyle: "normal",
    fontWeight: 600,
    lineHeight: "normal",
    textAlign: "center"
});

const AllowAccessDetailText = styled(Typography)({
    color: "var(--Scio-Services-Neutral-400---Neutral, #8B989F)",
    textAlign: "center",
    fontFamily: "Poppins",
    fontSize: "14px",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "normal",
    width: "335px",
    margin: "auto"
});

const AccessModalContentMainInnerBox = styled(Box)({
    height: "calc(100% - 77px)",
    background: "#fff",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-around"
});

const ModalImageMainBox = styled(Box)({
    height: "252px",
    width: "213px",
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    alignSelf: "center",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    position: "relative",
    borderRadius: "4px"
});

const ImageContainBox = styled(Box)({
    background: "#fff",
    height: "99%",
    width: "99%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "4px",
    position: "absolute",
});

const DenyImage = styled("img")({
    margin: "40px",
    background: "red",
    position: "absolute",
    top: -10,
    left: -70,
});

const ModalImage = styled("img")({
    height: "134px",
    width: "134px"
});

const ModalCameraImage = styled("img")({
    height: "104px",
    width: "104px"
});

const ModalButtonMainBox = styled(Box)({
    display: "flex",
    height: "100px",
    flexDirection: "column",
    margin: "0px 24px 0px 24px"
});

const ButtonsMainBox = styled(Box)({
    height: "88px",
    margin: "0px 24px 0px 24px",
});

const AccessModalMainBox = styled(Box)({
    height: "100vh",
    background: "white"
});

const UploadAllowButton = styled(Box)({
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    height: "44px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontStyle: "normal",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "0px"
});

const UploadAllowCameraButton = styled(Box)({
    background: "linear-gradient(90deg, #34B9FC -17.18%, #7B61FD 100%)",
    height: "44px",
    fontStyle: "normal",
    borderRadius: "8px",
    fontWeight: 500,
    lineHeight: "24px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontFamily: "Poppins",
    fontSize: "16px",
    padding: "0px"
});

const CancelReturnText = styled(Typography)({
    height: "44px",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    color: "#4FC3FD",
    fontStyle: "normal",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "24px",
    padding: "0px"
});

const CancelCameraReturnText = styled(Box)({
    height: "44px",
    display: "flex",
    color: "#4FC3FD",
    fontStyle: "normal",
    lineHeight: "24px",
    padding: "0px",
    borderRadius: "8px",
    fontFamily: "Poppins",
    fontSize: "16px",
    fontWeight: 500,
    alignItems: "flex-end",
    justifyContent: "center",
});

const HeaderMainBox = styled(Box)({
    height: "48px",
    marginBottom: "29px",
    display: "flex",
    justifyContent: "Center",
    alignItems: "center",
    background: "var(--Scio-Services-Basic-White, #FFF)",
});

const Logo = styled("img")({
    height: "36px",
    width: "71px",
});

const FileInputBox = styled("input")({
    alignSelf: "center",
    position: "absolute",
    opacity: 0,
    height: "40px",
    width: "200px",
});

const ContentBody = styled(Box)({
    margin: "190px 0px 0px 30px",
    "@media(max-width: 900px)": {
        margin: "100px 100px 100px 100px"
    },
    "@media(max-width: 435px)": {
        margin: "60px 100px 30px 100px"
    }
});

// Customizable Area End

export default class UploadNewPhotoMobile extends UploadNewPhotoMobileController {
    // Customizable Area Start
    header = () => {
        return (
            <>
                <Header
                    username={localStorage.getItem("user-name")}
                    propfileId={localStorage.getItem("user-id")}
                    profileImg={localStorage.getItem("user-info") || ""}
                    onMenuClick={this.handleDrowerToggle}
                    onProfileClick={this.navigate}
                />
                <ContentBody>
                    <Sidebar
                        mobileOpen={this.state.hasOpenSidebar}
                        handleClose={this.handleDrowerToggle}
                        navigation={this.props.navigation}
                        path={window.location.pathname.includes('survey')}
                    />
                </ContentBody>
            </>
        )
    }

    renderUploadMedia = () => {
        return (
            <UploadMediaContainer>
                <HeaderIconMainBox
                    data-test-id="backButtonId"
                    onClick={this.goBack}>
                    <BackIcon src={back} />
                    <UploadMediaTitleText>{configJSON.uploadNewPhotoVideoTitle}</UploadMediaTitleText>
                </HeaderIconMainBox>
                {this.renderSelectMainBox()}
                {this.renderButtons()}
            </UploadMediaContainer>
        )
    }

    renderSelectMainBox = () => {
        return (
            <DashedContainer>
                <GalleryBox
                    data-test-id="galleryButtonId"
                    onClick={this.handleGalleryClick}>
                    <GalleryImage src={galleryImage} />
                    <ImageTitleText>{configJSON.fromGalleryText}</ImageTitleText>
                </GalleryBox>
                <GalleryBox
                    data-test-id="cameraButtonId"
                    onClick={this.handleCameraClick}>
                    <GalleryImage src={cameraImage} />
                    <ImageTitleText>{configJSON.fromCameraText}</ImageTitleText>
                </GalleryBox>
            </DashedContainer>
        )
    }

    renderButtons = () => {
        return (
            <ButtonsMainBox>
                <UploadAllowButton>{configJSON.uploadDocuments}</UploadAllowButton>
                <CancelReturnText data-test-id="cancelButtonId" onClick={this.goBack}>{configJSON.cancel}</CancelReturnText>
            </ButtonsMainBox>
        )
    }

    galleryModal = () => {
        return (
            <Modal
                open={this.state.isPermissionModalOpen}>
                <AccessModalMainBox>
                    <HeaderMainBox>
                        <Logo src={buildingInfoLogo} />
                    </HeaderMainBox>
                    <AccessModalContentMainInnerBox>
                        <ModalImageMainBox>
                            <ImageContainBox>
                                <ModalImage src={galleryImage} />
                            </ImageContainBox>
                            {!this.state.isGalleryAccessAllowed && <DenyImage src={deny} />}
                        </ModalImageMainBox>
                        <ModalTextMainBox>
                            <AllowAccessText>{this.state.isGalleryAccessAllowed ? configJSON.allowGalleryaccessText : configJSON.noGalleryAccess}</AllowAccessText>
                            <AllowAccessDetailText>{this.state.isGalleryAccessAllowed ? configJSON.allowAcessParaText : configJSON.galleryAccessDeniedDetail}</AllowAccessDetailText>
                        </ModalTextMainBox>
                        <ModalButtonMainBox >
                            <UploadAllowButton
                                data-test-id="uploadButtonTestId"
                                onClick={this.permissionGranted}>
                                {this.state.ispermissionGranted ? <FileInputBox data-testid="imageFileHandlerid" type="file" /> : <FileInputBox type="file" accept="image/*;capture=camera" />}
                                {configJSON.allowAcessText}
                            </UploadAllowButton>
                            <CancelReturnText
                                data-test-id="permissionDenyTestId"
                                onClick={!this.state.isGalleryAccessAllowed ? this.goBack : this.galleryAccessPermission}>
                                {this.state.isGalleryAccessAllowed ? configJSON.AccessDeniedText : configJSON.ReturnHomeText}
                            </CancelReturnText>
                        </ModalButtonMainBox>
                    </AccessModalContentMainInnerBox>
                </AccessModalMainBox>
            </Modal>
        )
    }

    cameraModal = () => {
        return (
            <Modal
                open={this.state.isCameraModalOpen}>
                <AccessModalMainBox>
                    <HeaderMainBox>
                        <Logo src={buildingInfoLogo} />
                    </HeaderMainBox>
                    <AccessModalContentMainInnerBox>
                        <ModalImageMainBox>
                            <ImageContainBox>
                                <ModalCameraImage src={cameraAccess} />
                            </ImageContainBox>
                            {!this.state.isGalleryAccessAllowed && <DenyImage src={deny} />}
                        </ModalImageMainBox>
                        <ModalTextMainBox>
                            <AllowAccessText>{this.state.isGalleryAccessAllowed ? configJSON.allowCameraAccessText : configJSON.noCameraAccess}</AllowAccessText>
                            <AllowAccessDetailText>{this.state.isGalleryAccessAllowed ? configJSON.cameraAccessDetailText : configJSON.cameraDeniedDetailText}</AllowAccessDetailText>
                        </ModalTextMainBox>
                        <ModalButtonMainBox >
                            <UploadAllowCameraButton
                                data-test-id="uploadButtonTestId"
                                onClick={this.permissionGranted}>
                                {this.state.ispermissionGranted ? <FileInputBox data-testid="imageFileHandlerid" type="file" /> : <FileInputBox type="file" accept="image/*;capture=camera" />}
                                {configJSON.allowAcessText}
                            </UploadAllowCameraButton>
                            <CancelCameraReturnText
                                data-test-id="permissionDenyTestId"
                                onClick={!this.state.isGalleryAccessAllowed ? this.goBack : this.galleryAccessPermission}>
                                {this.state.isGalleryAccessAllowed ? configJSON.AccessDeniedText : configJSON.ReturnHomeText}
                            </CancelCameraReturnText>
                        </ModalButtonMainBox>
                    </AccessModalContentMainInnerBox>
                </AccessModalMainBox>
            </Modal>
        )
    };
    // Customizable Area End
    render() {
        // Customizable Area Start
        return (
            <Box>
                {this.header()}
                {this.renderUploadMedia()}
                {this.cameraModal()}
                {this.galleryModal()}
            </Box>
        )
        // Customizable Area End
    }
}

// Customizable Area Start
// Customizable Area End