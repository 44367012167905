import React, {createRef} from "react";
// Customizable Area Start
import Speach from "../../../components/src/Speach"
import {
  styled,
  Box,
  Typography,
  Accordion,
  AccordionSummary,
  withStyles,
  TextField,
  Button,
  Grid
} from "@material-ui/core";
import { CustomField } from "./CustomField";
import { AddSimilarSectionButton } from "./CustomFormWrapper";
import ExpandMoreIcon from "@material-ui/icons/ExpandMore";
import ExpandLessIcon from "@material-ui/icons/ExpandLess";
import { SurveyController, Props } from "./SurveyController";
import { subSecDeleteButton } from "../src/assets";
import { VoiceInputButton } from "./VoiceInputButton";
import {  PhotosNew } from "./CustomFormController.web";
import SubsectionDeletePopup from "./SubsectionDeletePopup.web";
// Customizable Area End

// Customizable Area Start
const FormWrapper = styled(Box)({
  marginTop: "30px",
  padding: "24px 120px 30px 140px",

  boxShadow: "0px 2px 8px 0px rgba(0, 0, 0, 0.08)",
  borderRadius: "8px",
  borderTop: "10px solid #4FC3FD",
  borderBottom: "10px solid #7B61FD",
  borderLeft: "1px solid #E2E8F0",
  borderRight: "1px solid #E2E8F0",

  "@media(max-width: 900px)": {
    marginTop: '23px',
    border: "none",
    boxShadow: "none",
    padding: "10px 24px 40px 24px",
    position: "relative",
  },
});

const CustomAccordionBox = styled(Box)({
  display: "flex",
  marginTop: "15px"
});

const SectionTitle = styled(Typography)({
  fontSize: "25px",
  fontFamily: "Poppins, Inter, sans-serif",
  color: "#000000",
  fontWeight: 400,
  textAlign: "center",

  "@media(max-width: 435px)": {
    fontSize: "16px",
    fontWeight: 700,
  },
});

const Heading = styled(Typography)({
  fontSize: "20px",
  display: "flex",
  alignItems: "center",
  "@media(max-width: 650px)": {
    fontSize: '14px'
  }
});

const CustomAccordionSummary = styled(AccordionSummary)({
  width:'100%',
  border: "1px solid #E5E5E5",
  height: "50px",
  borderRadius: "4px",
  "& .MuiAccordionSummary-content": {
    justifyContent: "center",
  },
  "@media(max-width: 435px)": {
    minHeight: '50px !important'
  }
});

const CustomAccordionForm = styled(Box)({
  display: "flex",
  justifyContent: "flex-start",
  alignContent: "center",
  gap: "20px",
  flexDirection: "column",
  width: "100%",
  margin: "10px 0px 10px 0px",
});

const DeleteSubSecButton = styled("img")({
padding:"0px 0px 0px 20px",
cursor:"pointer",
})

const styles = () => ({
  root: {
    boxShadow: "none",
    marginBottom: "10px",
    marginTop: "10px",
    border: "none",
    width: "100%",
    "&$expanded": {
      margin: "auto",
      border: "none",
      width: "100%",
    },
  },
  expanded: {},
});

const BulkUploadingFiles = styled(Grid)({
  width: "100%",
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
  gap:"10px"
});

const ImagesVideosFiles = styled(Box)({
  height: "329px",
  width: "100%"
});

const DeleteCustomButton = styled(Button)({
  textTransform: "none",
  color: "#DC2626",
  fontSize: "16px",
  fontWeight: 500,
  fontFamily: "Poppins",
  width: "100%",
  textTransForm: "none",
  "&:hover": {
    background: "none"
  }
});

const CustomTextField = styled(TextField)({
   width: "100%",
  "& .MuiOutlinedInput-input": {
    padding: "16px 15px",
    borderRadius: "4px",
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "#E5E5E5",
  },
  "& label.Mui-focused": {
    color: "#E5E5E5",
  },
  "& .MuiInput-underline:after": {
    borderBottomColor: "#0094A7",
  },
  "& .MuiOutlinedInput-root": {
    "& fieldset": {
      borderColor: "#red",
    },
    "&:hover fieldset": {
      borderColor: "#E5E5E5",
    },
    "&.Mui-focused fieldset": {
      borderColor: "#E5E5E5",
    },
  },
  "& input::placeholder": {
      padding: "15px 8px",
      fontSize: "20px",
      fontWeight: 400,
      lineHeight: "normal",
      color: "darkgray",
      whiteSpace: "nowrap",
      fontFamily: "Poppins",
  },
});

const ImageFiles = styled('img')({
 height: "100%",
 width: "100%",
 objectFit: 'contain'
});

const VideoFiles = styled('video')({
  height: "100%",
  width: "100%",
  objectFit: 'contain'
 });

const FilesContainer = styled(Box)({
 margin: "30px 0px 30px 0px"
});

const DeleteModalBlurBackground = styled(Box)({
  position: "fixed",
  left: 0,
  width: "100%",
  background: "#181818",
  height: "100%",
  opacity: 0.5,
  top: 75,
  zIndex: 9998
});
// Customizable Area End

const DeleteSubsectionButton = ({ handleDeleteSubsectionPopup, subsectionName }:any) => {
  return (
    <DeleteSubSecButton
      data-test-id="deleteSubSecId"
      onClick={() => handleDeleteSubsectionPopup(subsectionName)}
      src={subSecDeleteButton}
      alt=""
    />
  );
};


const CustomAccordion = withStyles(styles)(Accordion);
export class Survey extends SurveyController {
  constructor(props: Props) {
    super(props);
  }
  renderMuiltipleSection = () => {
    const {  handleAccordionToggle, handleDeleteSubsectionPopup,openAccordionsNew, headingRefs } = this.props;
    let sortedAccordian = openAccordionsNew.slice().sort((a, b) => {
      const idA = a.subsectionId ? parseInt(a.subsectionId) : 0;
      const idB = b.subsectionId ? parseInt(b.subsectionId) : 0;
      return idA - idB;
    }); 
    return (
      <>
        {sortedAccordian.map(({isOpen, subsectionName}) => {
        headingRefs[subsectionName] = createRef();
          return(
          <CustomAccordionBox key={subsectionName} data-test-id = "accordianContainerId" >
            <CustomAccordion
              ref={headingRefs[subsectionName]}
              data-testid="handleAccordionId"
              expanded={isOpen}
              onChange={() => handleAccordionToggle(subsectionName)}

            >
              <div style={{display:'flex', justifyContent:'space-between', alignItems: "center"}}>
                <CustomAccordionSummary>
                  <Heading data-test-id="subsectionHeader">
                    {subsectionName}
                    {isOpen ? <ExpandLessIcon /> : <ExpandMoreIcon />}
                  </Heading>
                </CustomAccordionSummary>
                <Box>
                  <DeleteSubsectionButton
                    handleDeleteSubsectionPopup={handleDeleteSubsectionPopup}
                    subsectionName={subsectionName}
                  />
                </Box>
              </div>
              {this.renderCustomForm(subsectionName)}
            </CustomAccordion>
          </CustomAccordionBox>
        )})}
      </>
    );
  };

  renderCustomForm = (sectionName: string = this.props.name) => {
    
    const { fields, openBulkUploadImages, handleBlurFields, dynamicOptions } = this.props;
    return (
      <CustomAccordionForm>
        {fields.map((field) => (
          <>
          <CustomField
          handleBlurFields={handleBlurFields}
          handleMouseDown={()=>this.handleSpeechStart(sectionName,field.field_name)}
          handleMouseRelease={this.handleSpeechStop}
           data-testid="customeTextId"
            key={sectionName + field.field_name}
            {...field}
            fieldRef={
              this.isScrollableField(field.field_name)
                ? this.onSelectField
                : undefined
            }
            value={this.props.fieldValues?.[sectionName]?.[field.field_name]}
            handleChange={(e)=>{
              this.createChangeHandler(
              sectionName,
              field.field_name,
              e,
              field.dynamic_dropdown_type
            )}}
            dynamicOptions={dynamicOptions}
            isError ={this.checkMondatoryFields(sectionName,field.field_name)}
            openBulkUploadImages={(e: React.MouseEvent<HTMLButtonElement>)=>openBulkUploadImages(e,field.id,sectionName)}
          />
          {this.props.uploadPictures[sectionName] && this.renderUploadedFiles(sectionName, field.id)} 
          </>
        ))}
      </CustomAccordionForm>
    );
  };

  renderUploadedFiles = (sectionName: string, id: string | number) => {
    const { uploadPictures, videoNotSupported, deleteIcon, handleDeleteFiles, deleteImageButton, handleDescriptionChange } = this.props;
    const sectionPhotos = uploadPictures[sectionName];
    const subSectionDetails = sectionPhotos.filter((photos: { fieldId: string | number; })=>{
      return photos.fieldId == id;
    });
    if (!subSectionDetails || subSectionDetails.length === 0) {
        return null;
    }
    return (
      <FilesContainer>
         <Grid container spacing={4}>
        {subSectionDetails.map((file: PhotosNew, index: number) => (
             <Grid item xl={6} lg={6} md={12} sm={12} xs={12}  key={index}>
          <BulkUploadingFiles>
            <ImagesVideosFiles>
              {file.content_type&& file.content_type.startsWith('video/') ? (
                <VideoFiles controls>
                  <source src={file.url} type={file.content_type} />
                  {videoNotSupported}
                </VideoFiles>
              ) : (
                <ImageFiles src={file.url} alt={`Image ${index}`} />
              )}
            </ImagesVideosFiles>
            <DeleteCustomButton
              disableRipple
              data-testid="deleteUploadingFiles"
              color="secondary"
              startIcon={<img src={deleteIcon} alt="Delete icon" />}
              onClick={()=>handleDeleteFiles(file,file.subSectionNew,index)}
            >
              {deleteImageButton} {file.content_type.split("/")[0]}
            </DeleteCustomButton>
            <CustomTextField data-testid="addDescription" value={file.description} variant="outlined" placeholder="Write a description" onChange={(e: React.ChangeEvent<HTMLInputElement>)=>handleDescriptionChange(file, e.target.value)}
              InputProps={{
                endAdornment: <Speach handleGetText={(text:string)=>{
                  handleDescriptionChange(file, text)
                  this.setState({ fileData: file, speech_type: 'description'});
                }}/>
              }}
            />
          </BulkUploadingFiles>
           </Grid>
        ))}
        </Grid>
      </FilesContainer>
    );
  };
  
  render(): React.ReactNode {
    const {
      openAccordionsOpen,
      name,
      add_similar_section,
      handleAddBuilding,
      copy_from_last_filled,
      add_similar_section_text,
      openBulkUploadImages,
      deleteFileConfirmationPopup,
      handleCloseDeleteFilePopup,
      handleOpenDeletePopup,
      fileFormat,
      subsectionName,
      handleBlurFields
    } = this.props;

    // Customizable Area Start
    return (
      <FormWrapper>
        {openAccordionsOpen ? (
          this.renderMuiltipleSection()
        ) : (
          <>
            <SectionTitle>{name}</SectionTitle>
            {this.renderCustomForm(name || subsectionName)}
          </>
        )}
        {add_similar_section && (
          <AddSimilarSectionButton
            data-test-id="AddSimilarSectionButton"
            onClick={()=>{
              this.disableCopyCheckBox();
              handleAddBuilding();
            }}
          >
            {add_similar_section_text}
          </AddSimilarSectionButton>
        )}
        {(copy_from_last_filled && openAccordionsOpen) && (
          <div style={{marginTop: "20px"}}>
          <CustomField
            field_name="Copy from the last filled?"
            field_options={["Yes"]}
            field_type="checkbox"
            value={this.state.copyCheckBox ? ["Yes"] : []}
            handleChange={()=>this.handleCopyCheck(name)}
            is_mandatory={false}
            dynamic_dropdown_type={''}
            is_speech_to_text_required={false}
            id={-1}
            is_upload_image_video_required={false}
            data-test-id="copy_from_last_filled"
            isError={false}
            dynamicOptions={{
              data: {
                user_survey: {
                  id: "",
                  survey_name: "",
                  created_by: "",
                  owner_name: "",
                  survey_template_id: "",
                  status: "",
                  is_deleted: null,
                  created_at: "",
                  updated_at: "",
                  account_id: ""
                },
                survey_associated_buildings: [],
                survey_associated_floors: []
              }
            }}
            openBulkUploadImages={(e: React.MouseEvent<HTMLButtonElement>) => openBulkUploadImages(e, 1, subsectionName)} 
            handleBlurFields={handleBlurFields}
          />
          </div>

        )}

      {deleteFileConfirmationPopup && <><DeleteModalBlurBackground/>
      <SubsectionDeletePopup 
         data-testid="deletePopupId"
         handleCloseDeleteSubsecPopup={handleCloseDeleteFilePopup}
         message={`Are you sure you want to delete the ${fileFormat}?`} 
         firstBtnText={"No"} 
         secondBtnText={"Yes"}
         handleDeleteElementAccordion={handleOpenDeletePopup}
         /> </>
       }
      </FormWrapper>
    );
    // Customizable Area End
  }
}
// Customizable Area Start
// Customizable Area End